import React, { useContext } from "react";
import find from "lodash/find";
import { PageContext } from "@Core";
import Item from "./Item";

import styles from "./Menu.styles";

const Menu = () => {
	const [pageData] = useContext(PageContext);
	const { menuGroups = [] } = pageData?.data || {};
	const css = styles();

	const menuItems = find(menuGroups, function (o) {
		return o.title === "Touchmenü";
	})?.items;

	return (
		<div className={css.menu}>
			{menuItems?.map((item, index) => {
				const { icon, link, linkRel, target, title } = item;

				return (
					<Item
						icon={icon}
						key={index}
						link={link}
						linkRel={linkRel}
						target={target}
						title={title}
					/>
				);
			})}
		</div>
	);
};

export default Menu;
