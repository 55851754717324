import makeStyles from "@material-ui/styles/makeStyles";

const styles = makeStyles(theme => ({
	weather: {
		alignItems: "center",
		background: theme.palette.background.light,
		display: "grid",
		gap: theme.spacing(2),
		gridTemplateColumns: "auto 1fr",
		padding: theme.spacing(0, 0, 0, 3)
	},
	icon: {
		"& img": {
			display: "block",
			filter:
				"invert(54%) sepia(2%) saturate(3225%) hue-rotate(355deg) brightness(121%) contrast(58%)"
		}
	},
	content: {
		textAlign: "right"
	},
	date: {
		textTransform: "uppercase"
	}
}));

export default styles;
