const theme = {
	typography: {
		fontFamily: "Azo Sans",
		fontSize: 18,
		fontWeightLight: 300,
		fontWeightRegular: 400,
		fontWeightMedium: 500,
		fontWeightBold: 700,
		h1: {
			fontFamily: "'Marat-Regular'",
			fontWeight: 500,
			fontSize: "clamp(32px, 6vw, 40px)",
			lineHeight: 1.2
		},
		h2: {
			fontFamily: "'Marat-Regular'",
			fontWeight: 500,
			fontSize: "clamp(22px, 5vw, 28px)",
			lineHeight: 1.2
		},
		h3: {
			fontFamily: "'Marat-Regular'",
			fontWeight: 500,
			fontSize: "clamp(20px, 5vw, 24px)",
			lineHeight: 1.2
		},
		h4: {
			fontFamily: "'Azo Sans'",
			fontWeight: 700,
			fontSize: 20,
			lineHeight: 1.2
		},
		h5: {
			fontFamily: "'Azo Sans'",
			fontWeight: 700,
			fontSize: 20,
			lineHeight: 1.2
		},
		h6: {
			fontFamily: "'Azo Sans'",
			fontWeight: 700,
			fontSize: 20,
			lineHeight: 1.2
		},
		subtitle1: {
			fontFamily: "'Azo Sans'",
			fontWeight: 700,
			fontSize: 18,
			lineHeight: 1.25
		},
		subtitle2: {
			fontFamily: "'Azo Sans'",
			fontWeight: 700,
			fontSize: 16,
			lineHeight: 1.125
		},
		overline: {
			fontFamily: "'Azo Sans'",
			fontWeight: 700,
			fontSize: 14,
			lineHeight: 1.2
		},
		body1: {
			fontFamily: "'Azo Sans'",
			fontWeight: 400,
			fontSize: 18,
			lineHeight: 1.5
		},
		body2: {
			fontFamily: "'Azo Sans'",
			fontWeight: 400,
			fontSize: 16,
			lineHeight: 1.2
		},
		caption: {
			fontFamily: "'Azo Sans'",
			fontWeight: 400,
			fontSize: 15,
			lineHeight: 1.2
		},
		button: {
			fontFamily: "'Azo Sans'",
			fontWeight: 500,
			fontSize: 18,
			lineHeight: 1.2
		}
	},
	palette: {
		primary: {
			light: "#787776",
			main: "#575654",
			dark: "#3C3C3A",
			contrastText: "#FFFFFF"
		},
		secondary: {
			light: "#B2AA9F",
			main: "#9F9587",
			dark: "#6F685E",
			contrastText: "#ffffff"
		},
		background: {
			header: {
				appBar: "#FFFFFF",
				avatar: "#4F4F4A",
				bookmark: "#FFFFFF",
				pageTitle: "#575654"
			},
			footer: {
				bar: "#4F4F4A"
			},
			default: "#FFFFFF",
			drawer: "#EEE9E3",
			menuTree: "#EEE9E3",
			paper: "#FFFFFF",
			teaser: "#EEE9E3",
			light: "#EEE9E3",
			input: "#EEE9E3",
			text: "#EEE9E3",
			bookmark: "#4F4F4A",
			bookmarkActive: "#575654",
			allergenIcon: "#4F4F4A",
			infoAZItem: "#4F4F4A",
			pwaAlert: "#4F4F4A",
			contactOverlay: "#FFFFFF",
			socialMediaItem: "#FFFFFF",
			menuForm: "#EEE9E3"
		},
		foreground: {
			header: {
				appBar: "#FFFFFF",
				avatar: "#FFFFFF",
				bookmark: "#575654",
				language: "#575654",
				pageTitle: "#FFFFFF"
			},
			contact: {
				label: "#4F4F4A"
			},
			footer: {
				navigation: "#FFFFFF",
				navigationHover: "#9F9587",
				navigationSelected: "#9F9587"
			},
			menu: {
				navigation: "#4F4F4A",
				navigationHover: "#9F9587",
				navigationSelected: "#9F9587"
			},
			overlay: "#FFFFFF",
			bookmark: "#FFFFFF",
			socialMediaItem: "#4F4F4A"
		},
		text: {
			dark: "#000000",
			primary: "#4F4F4A",
			divider: "#CCCCCC",
			gradient: {
				from: "rgba(255, 255, 255, 0.74)",
				to: "#ffffff"
			}
		},
		divider: "#CCCCCC",
		menuToggle: "#575654",
		elementHeadline: "#575654",
		lateCheckOut: "#DFBDA3",
		greenLiving: "#BDCCA9"
	},
	shadows: [
		"none",
		"0px 2px 1px -1px rgba(145, 158, 171, 0.2),0px 1px 1px 0px rgba(145, 158, 171, 0.14),0px 1px 3px 0px rgba(145, 158, 171, 0.12)",
		"0px 3px 1px -2px rgba(145, 158, 171, 0.2),0px 2px 2px 0px rgba(145, 158, 171, 0.14),0px 1px 5px 0px rgba(145, 158, 171, 0.12)",
		"0px 3px 3px -2px rgba(145, 158, 171, 0.2),0px 3px 4px 0px rgba(145, 158, 171, 0.14),0px 1px 8px 0px rgba(145, 158, 171, 0.12)",
		"0px 2px 4px -1px rgba(145, 158, 171, 0.2),0px 4px 5px 0px rgba(145, 158, 171, 0.14),0px 1px 10px 0px rgba(145, 158, 171, 0.12)",
		"0px 3px 5px -1px rgba(145, 158, 171, 0.2),0px 5px 8px 0px rgba(145, 158, 171, 0.14),0px 1px 14px 0px rgba(145, 158, 171, 0.12)",
		"0px 3px 5px -1px rgba(145, 158, 171, 0.2),0px 6px 10px 0px rgba(145, 158, 171, 0.14),0px 1px 18px 0px rgba(145, 158, 171, 0.12)",
		"0px 4px 5px -2px rgba(145, 158, 171, 0.2),0px 7px 10px 1px rgba(145, 158, 171, 0.14),0px 2px 16px 1px rgba(145, 158, 171, 0.12)",
		"0px 5px 5px -3px rgba(145, 158, 171, 0.2),0px 8px 10px 1px rgba(145, 158, 171, 0.14),0px 3px 14px 2px rgba(145, 158, 171, 0.12)",
		"0px 5px 6px -3px rgba(145, 158, 171, 0.2),0px 9px 12px 1px rgba(145, 158, 171, 0.14),0px 3px 16px 2px rgba(145, 158, 171, 0.12)",
		"0px 6px 6px -3px rgba(145, 158, 171, 0.2),0px 10px 14px 1px rgba(145, 158, 171, 0.14),0px 4px 18px 3px rgba(145, 158, 171, 0.12)",
		"0px 6px 7px -4px rgba(145, 158, 171, 0.2),0px 11px 15px 1px rgba(145, 158, 171, 0.14),0px 4px 20px 3px rgba(145, 158, 171, 0.12)",
		"0px 7px 8px -4px rgba(145, 158, 171, 0.2),0px 12px 17px 2px rgba(145, 158, 171, 0.14),0px 5px 22px 4px rgba(145, 158, 171, 0.12)",
		"0px 7px 8px -4px rgba(145, 158, 171, 0.2),0px 13px 19px 2px rgba(145, 158, 171, 0.14),0px 5px 24px 4px rgba(145, 158, 171, 0.12)",
		"0px 7px 9px -4px rgba(145, 158, 171, 0.2),0px 14px 21px 2px rgba(145, 158, 171, 0.14),0px 5px 26px 4px rgba(145, 158, 171, 0.12)",
		"0px 8px 9px -5px rgba(145, 158, 171, 0.2),0px 15px 22px 2px rgba(145, 158, 171, 0.14),0px 6px 28px 5px rgba(145, 158, 171, 0.12)",
		"0px 8px 10px -5px rgba(145, 158, 171, 0.2),0px 16px 24px 2px rgba(145, 158, 171, 0.14),0px 6px 30px 5px rgba(145, 158, 171, 0.12)",
		"0px 8px 11px -5px rgba(145, 158, 171, 0.2),0px 17px 26px 2px rgba(145, 158, 171, 0.14),0px 6px 32px 5px rgba(145, 158, 171, 0.12)",
		"0px 9px 11px -5px rgba(145, 158, 171, 0.2),0px 18px 28px 2px rgba(145, 158, 171, 0.14),0px 7px 34px 6px rgba(145, 158, 171, 0.12)",
		"0px 9px 12px -6px rgba(145, 158, 171, 0.2),0px 19px 29px 2px rgba(145, 158, 171, 0.14),0px 7px 36px 6px rgba(145, 158, 171, 0.12)",
		"0px 10px 13px -6px rgba(145, 158, 171, 0.2),0px 20px 31px 3px rgba(145, 158, 171, 0.14),0px 8px 38px 7px rgba(145, 158, 171, 0.12)",
		"0px 10px 13px -6px rgba(145, 158, 171, 0.2),0px 21px 33px 3px rgba(145, 158, 171, 0.14),0px 8px 40px 7px rgba(145, 158, 171, 0.12)",
		"0px 10px 14px -6px rgba(145, 158, 171, 0.2),0px 22px 35px 3px rgba(145, 158, 171, 0.14),0px 8px 42px 7px rgba(145, 158, 171, 0.12)",
		"0px 11px 14px -7px rgba(145, 158, 171, 0.2),0px 23px 36px 3px rgba(145, 158, 171, 0.14),0px 9px 44px 8px rgba(145, 158, 171, 0.12)",
		"0px 11px 15px -7px rgba(145, 158, 171, 0.2),0px 24px 38px 3px rgba(145, 158, 171, 0.14),0px 9px 46px 8px rgba(145, 158, 171, 0.12)",
		{
			header: "0 2px 4px 0 rgba(0, 0, 0, 0.1)",
			z1: "0 1px 2px 0 rgba(145, 158, 171, 0.24)",
			z8: "0 8px 16px 0 rgba(145, 158, 171, 0.24)",
			z12: "0 0 2px 0 rgba(145, 158, 171, 0.24), 0 12px 24px 0 rgba(145, 158, 171, 0.24)",
			z16: "0 0 2px 0 rgba(145, 158, 171, 0.24), 0 16px 32px -4px rgba(145, 158, 171, 0.24)",
			z20: "0 0 2px 0 rgba(145, 158, 171, 0.24), 0 20px 40px -4px rgba(145, 158, 171, 0.24)",
			z24: "0 0 4px 0 rgba(145, 158, 171, 0.24), 0 24px 48px 0 rgba(145, 158, 171, 0.24)",
			primary: "0 8px 16px 0 rgba(0, 171, 85, 0.24)",
			info: "0 8px 16px 0 rgba(24, 144, 255, 0.24)",
			success: "0 8px 16px 0 rgba(84, 214, 44, 0.24)",
			warning: "0 8px 16px 0 rgba(255, 193, 7, 0.24)",
			error: "0 8px 16px 0 rgba(255, 72, 66, 0.24)"
		}
	],
	shape: {
		borderRadius: 0
	},
	overrides: {
		MuiCssBaseline: {
			"@global": {
				html: {
					"--palette-primary-main": "#900828"
				}
			}
		},
		MuiButton: {
			containedPrimary: {
				backgroundColor: "#D90000"
			},
			outlinedPrimary: {
				borderColor: "#D90000",
				color: "#D90000",
				"&:hover": {
					borderColor: "#3C3C3A",
					color: "#3C3C3A"
				}
			},
			root: {
				boxShadow: "none !important",
				fontSize: 18,
				padding: "12px 16px !important"
			},
			sizeSmall: {
				fontSize: 16,
				padding: "8px 16px !important"
			},
			sizeLarge: {
				fontSize: 20,
				padding: "16px !important"
			}
		},
		MuiAutocomplete: {
			endAdornment: {
				marginRight: "16px"
			}
		},
		MuiFab: {
			primary: {
				backgroundColor: "#D90000"
			}
		}
	},
	zIndex: {
		image: {
			overlay: 2,
			content: 3
		},
		bookmark: 4,
		text: 900,
		fabButton: 1000,
		socialMedia: 1100,
		footer: 1200,
		fabButtonInFooter: 1210,
		menuForm: 1220,
		header: 1300,
		drawer: 1500,
		overlay: 1600,
		loader: 1700
	},
	customTypography: {
		h7: {
			fontSize: 20,
			fontWeight: 700,
			lineHeight: 1
		},
		infoLabel: {
			color: "#4F4F4A",
			fontWeight: 700
		},
		allergensLabel: {
			fontSize: 14
		},
		allergensExcludeLabel: {
			fontSize: 16
		},
		infoAZLabel: {
			fontSize: 16,
			fontWeight: 700
		},
		teamDepartmentTitle: {
			color: "#4F4F4A",
			fontSize: 24,
			fontWeight: 700,
			margin: "40px 0",
			textTransform: "uppercase",
			"& span": {
				borderBottom: "1px solid #575654",
				display: "inline-block",
				padding: "0 0 3px"
			}
		},
		teamEmployeeTitle: {
			color: "#4F4F4A",
			fontSize: 20,
			fontWeight: 700
		},
		ratingTitle: {
			color: "#4F4F4A",
			fontSize: 18,
			fontWeight: 700,
			textTransform: "uppercase",
			"& span": {
				borderBottom: "1px solid #575654",
				display: "inline-block",
				padding: "0 0 3px"
			}
		},
		loadingText: {
			color: "#FFFFFF",
			fontSize: "clamp(16px, 2vw, 24px)",
			fontWeight: 600
		},
		languageTitle: {
			color: "#575654",
			fontSize: 24,
			fontWeight: 700
		}
	}
};

export default theme;
