function aroundMeMarkersConfig() {
	return [
		{
			default: true,
			anchor: [24, 48],
			popupAnchor: [0, -48],
			size: [48, 48],
			url: "/img/elements/around-me/marker/default.svg"
		},
		{
			anchor: [24, 48],
			identifier: "main",
			popupAnchor: [0, -48],
			size: [48, 48],
			url: "/img/elements/around-me/marker/hotel-kaiser.svg"
		},
		{
			anchor: [24, 48],
			identifier: "Restaurant",
			popupAnchor: [0, -48],
			size: [48, 48],
			url: "/img/elements/around-me/marker/restaurant.svg"
		},
		{
			anchor: [24, 48],
			identifier: "restaurant",
			popupAnchor: [0, -48],
			size: [48, 48],
			url: "/img/elements/around-me/marker/restaurant.svg"
		},
		{
			anchor: [24, 48],
			identifier: "Alm / Hütte / Berggasthof",
			popupAnchor: [0, -48],
			size: [48, 48],
			url: "/img/elements/around-me/marker/alm.svg"
		},
		{
			anchor: [24, 48],
			identifier: "Pasture / hut / mountain lodge",
			popupAnchor: [0, -48],
			size: [48, 48],
			url: "/img/elements/around-me/marker/alm.svg"
		},
		{
			anchor: [24, 48],
			identifier: "Infobüro",
			popupAnchor: [0, -48],
			size: [48, 48],
			url: "/img/elements/around-me/marker/tvb.svg"
		},
		{
			anchor: [24, 48],
			identifier: "Tourist Office",
			popupAnchor: [0, -48],
			size: [48, 48],
			url: "/img/elements/around-me/marker/tvb.svg"
		},
		{
			anchor: [24, 48],
			identifier: "Bergbahn / Seilbahn",
			popupAnchor: [0, -48],
			size: [48, 48],
			url: "/img/elements/around-me/marker/bergbahn.svg"
		},
		{
			anchor: [24, 48],
			identifier: "mountain railway / cable railway",
			popupAnchor: [0, -48],
			size: [48, 48],
			url: "/img/elements/around-me/marker/bergbahn.svg"
		},
		{
			anchor: [24, 48],
			identifier: "Bar / Pub / Disco",
			popupAnchor: [0, -48],
			size: [48, 48],
			url: "/img/elements/around-me/marker/bar.svg"
		},
		{
			anchor: [24, 48],
			identifier: "Bar / Pub / Disco",
			popupAnchor: [0, -48],
			size: [48, 48],
			url: "/img/elements/around-me/marker/bar.svg"
		},
		{
			anchor: [24, 48],
			identifier: "Geschäfte / Shops",
			popupAnchor: [0, -48],
			size: [48, 48],
			url: "/img/elements/around-me/marker/shop.svg"
		},
		{
			anchor: [24, 48],
			identifier: "Shops",
			popupAnchor: [0, -48],
			size: [48, 48],
			url: "/img/elements/around-me/marker/shop.svg"
		},
		{
			anchor: [24, 48],
			identifier: "Skibus / Wanderbus",
			popupAnchor: [0, -48],
			size: [48, 48],
			url: "/img/elements/around-me/marker/bus.svg"
		},
		{
			anchor: [24, 48],
			identifier: "Skibus / Wanderbus",
			popupAnchor: [0, -48],
			size: [48, 48],
			url: "/img/elements/around-me/marker/bus.svg"
		},
		{
			anchor: [24, 48],
			identifier: "Bus",
			popupAnchor: [0, -48],
			size: [48, 48],
			url: "/img/elements/around-me/marker/bus.svg"
		},
		{
			anchor: [24, 48],
			identifier: "bus",
			popupAnchor: [0, -48],
			size: [48, 48],
			url: "/img/elements/around-me/marker/bus.svg"
		},
		{
			anchor: [24, 48],
			identifier: "Modegeschäfte",
			popupAnchor: [0, -48],
			size: [48, 48],
			url: "/img/elements/around-me/marker/mode.svg"
		},
		{
			anchor: [24, 48],
			identifier: "Fashion stores",
			popupAnchor: [0, -48],
			size: [48, 48],
			url: "/img/elements/around-me/marker/mode.svg"
		},
		{
			anchor: [24, 48],
			identifier: "Erlebnispark / Freizeitpark",
			popupAnchor: [0, -48],
			size: [48, 48],
			url: "/img/elements/around-me/marker/mountain.svg"
		},
		{
			anchor: [24, 48],
			identifier: "fun park / leisure park",
			popupAnchor: [0, -48],
			size: [48, 48],
			url: "/img/elements/around-me/marker/mountain.svg"
		},
		{
			anchor: [24, 48],
			identifier: "Hallenbad",
			popupAnchor: [0, -48],
			size: [48, 48],
			url: "/img/icons/around-me/marker/schwimmbaeder.svg"
		},
		{
			anchor: [24, 48],
			identifier: "indoor swimming pool",
			popupAnchor: [0, -48],
			size: [48, 48],
			url: "/img/elements/around-me/marker/schwimmbaeder.svg"
		},
		{
			anchor: [24, 48],
			identifier: "Apotheke",
			popupAnchor: [0, -48],
			size: [48, 48],
			url: "/img/elements/around-me/marker/apotheken.svg"
		},
		{
			anchor: [24, 48],
			identifier: "pharmacy",
			popupAnchor: [0, -48],
			size: [48, 48],
			url: "/img/elements/around-me/marker/apotheken.svg"
		}
	];
}

export default aroundMeMarkersConfig;
