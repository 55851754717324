import React from "react";
import parseElement from "../helper/parseElement";

const ContentElements = props => {
	const { elements, lang } = props;

	if (!elements) return <></>;

	const parsed = elements.map(element => {
		return <React.Fragment key={element.id}>{parseElement(element, lang)}</React.Fragment>;
	});

	return <>{parsed}</>;
};

export default ContentElements;
