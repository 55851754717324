import React, { useContext, useEffect } from "react";
import ReactMetadata01 from "@micado-digital/react-metadata/ReactMetadata01";
import { PageContext } from "@Core";
import Header from "./Header";
import Menu from "./Menu";

import styles from "./Touch.styles";

const Touch = () => {
	const [pageData] = useContext(PageContext);
	const css = styles();

	useEffect(() => {
		sessionStorage.setItem("mco-touch", true);
	}, []);

	return (
		<>
			<ReactMetadata01
				canonical={pageData?.data.meta?.canonical}
				description={pageData?.data?.meta?.description}
				index={pageData?.data?.meta?.indexable}
				image={pageData?.data?.meta?.image}
				keywords={pageData?.data?.meta?.keywords}
				lat={pageData?.data?.meta?.lat}
				lang={pageData?.data.lang}
				links={pageData?.data?.meta?.links}
				lng={pageData?.data?.meta?.lng}
				title={pageData?.data?.meta?.title}
			/>
			<div className={css.touch}>
				<Header />
				<div className={css.menu}>
					<Menu />
				</div>
			</div>
		</>
	);
};

export default Touch;
