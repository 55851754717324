import { LayoutStandard, parseLayout as coreParseLayout } from "@Core";
import ContentElements from "./contentElements";
import Touch from "../views/Touch";

const parseLayout = ({ elements, lang, layoutTag, layoutVariant }) => {
	if (layoutVariant === "around-me") {
		return (
			<LayoutStandard fullscreen socialMedia>
				<ContentElements elements={elements} lang={lang}></ContentElements>
			</LayoutStandard>
		);
	}

	switch (layoutTag) {
		case "standard":
			return (
				<LayoutStandard socialMedia>
					<ContentElements elements={elements} lang={lang}></ContentElements>
				</LayoutStandard>
			);
		case "touch":
			return <Touch />;
		default:
			return coreParseLayout(layoutTag);
	}
};

export default parseLayout;
