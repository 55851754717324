import React, { useContext } from "react";
import { PageContext } from "@Core";
import parseLayout from "./parseLayout";

const DetectLayout = () => {
	const [pageData] = useContext(PageContext);

	if (!pageData?.data) {
		return <></>;
	}

	return parseLayout(pageData.data);
};

export default DetectLayout;
