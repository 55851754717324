import React from "react";
import Language from "./Language";
import Logo from "./Logo";
import Weather from "./Weather";

import styles from "./Header.styles";

const Header = () => {
	const css = styles();

	return (
		<div className={css.header}>
			<div className={css.language}>
				<Language />
			</div>
			<Logo />
			<div className={css.weather}>
				<Weather />
			</div>
		</div>
	);
};

export default Header;
