import makeStyles from "@material-ui/styles/makeStyles";

const styles = makeStyles(() => ({
	logo: {
		"& img": {
			display: "block"
		}
	}
}));

export default styles;
