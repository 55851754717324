import makeStyles from "@material-ui/styles/makeStyles";

const styles = makeStyles(theme => ({
	language: {
		background: theme.palette.background.light,
		padding: theme.spacing(0, 3, 0, 0)
	},
	button: {
		alignItems: "center",
		background: "none",
		borderRadius: "50%",
		color: theme.palette.primary.main,
		cursor: "pointer",
		border: `2px solid ${theme.palette.primary.main}`,
		display: "flex",
		height: 40,
		justifyContent: "center",
		transition: "background 300ms ease, color 300ms ease",
		width: 40,
		"&:hover": {
			background: theme.palette.primary.main,
			color: theme.palette.getContrastText(theme.palette.primary.main)
		}
	},
	label: {
		fontWeight: 900,
		textTransform: "uppercase"
	},
	dialogTitle: {
		padding: theme.spacing(2, 3, 1, 3),
		textAlign: "center"
	},
	dialogContent: {
		padding: theme.spacing(0, 3, 2, 3),
		textAlign: "center"
	},
	headline: {
		...theme?.customTypography?.languageTitle
	},
	link: {
		display: "block",
		marginBottom: theme.spacing(1),
		width: "100%",
		"&:last-child": {
			marginBottom: 0
		}
	}
}));

export default styles;
